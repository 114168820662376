import {Helmet} from 'react-helmet-async';
// @mui
import {Grid, Container, Typography, Stack, Button, Box} from '@mui/material';
// routes
import {Link as RouterLink} from "react-router-dom";

// components
import ProductCard from "../components/product-card";


// ----------------------------------------------------------------------
export default function ConsultancyPage() {
	const backgroundImageStyle = {
		backgroundColor: "#212B36",
		backgroundImage: `url('/assets/services.jpg')`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPositionX: 'left',
		backgroundPositionY: 'center',
	}

	return (
		<>
			<Helmet>
				<title> OmniumAI </title>
			</Helmet>
			<Grid container
			      sx={{
				      minHeight: '70vh',
				      minWidth: '100vw',
				      pl: {xs: 1, sm: 2, md: 5},
				      ...backgroundImageStyle
			      }}
			      justifyContent={"center"}
			      alignItems={"center"}
			      spacing={3}>
				<Grid item xs={8} md={4}>
					<Typography variant="h2" sx={{pt: 5}} color={"background.default"}>
						Consulting Services
					</Typography>
					<Typography variant="h4" sx={{my: 5}} paragraph color={"background.default"}>
						Tailored software solutions for the
						<Typography color={"success.main"} component={'span'}
									variant={"h4"}>{' '} Life Sciences</Typography>
						, solving problems with cutting edge technology.
					</Typography>
					<Box sx={{pb: {xs: 2, sm: 0}}}>
						<Button
							variant="contained"
							component={RouterLink}
							size={"large"}
							color={"primary"}
							to={"mailto:info@omniumai.com"}>
							Get in touch
						</Button>
					</Box>
				</Grid>
				<Grid item xs={4} md={8}>
				</Grid>
			</Grid>
			<Container maxWidth="xl"
			           sx={{px: {xs: 1, md: 3}, pt: {xs: 5, md: 10}, pb: 3}}>
				<Stack spacing={{xs: 10, md: 15, lg: 20}}>
					<div>
						<Grid container
						      justifyContent="center"
						      alignItems="center"
						      spacing={3}>

							<Grid item xs={12} md={4} sx={{textAlign: 'center'}}>
								<Typography variant={"h2"}>
									Solutions Crafted for Your Challenges
								</Typography>
								<Typography>
									We believe that modern biotech companies should have computational tools to handle your specific needs.
								</Typography>
							</Grid>
						</Grid>
					</div>
					<div>
						<Grid container
						      justifyContent="center"
						      alignItems="center"
						      spacing={3}>
							<Grid item xs={12} md={6}>
								<Typography variant={"h2"} color={"primary"} paragraph>
									Get a hold on your data
								</Typography>
								<Typography paragraph>
									No matter the goal, be it AI models or workflow automation, the first step is
									building proper information repositories.
								</Typography>
								<Typography paragraph>
									At OmniumAI we can help you build your own from either private or publicly available
									data sources.
									Our expertise includes biological, biochemical and biomedical datasets.
									We can help you open your data to your collaborators, clients and users with tailored
									web applications.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}}}>
									<ProductCard
										useCase={{
											cover: `/assets/db.png`,
											title: "",
											link: ""
										}}
										index={0}
									/>
								</Box>
							</Grid>
						</Grid>
					</div>
					<div>
						<Grid container
						      justifyContent="center"
						      alignItems="center"
						      direction="row-reverse"
						      spacing={3}>
							<Grid item xs={12} md={6}>
								<Typography variant={"h2"} color={"primary"} paragraph>
									Get Your Data Ready
								</Typography>
								<Typography paragraph>
									OmniumAI is specialized in developing high-throughput NGS and omics data processing
									pipelines. These pipelines make your raw data ready for downstream analysis.
								</Typography>
								<Typography paragraph>
									We can develop NGS pipelines for genome assembly, variant calling, and gene
									expression analysis tasks.
								</Typography>
								<Typography paragraph>
									We also develop omics pipelines for distinct types of clinical
									and biological data including genomics, epigenomics, transcriptomics, proteomics,
									and metabolomics.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}}}>
									<ProductCard
										useCase={{
											cover: `/assets/ngs.png`,
											title: "",
											link: ""
										}}
										index={0}
									/>
								</Box>
							</Grid>
						</Grid>
					</div>
					<div>
						<Grid container
						      justifyContent="center"
						      alignItems="center"
						      spacing={3}>
							<Grid item xs={12} md={6}>
								<Typography variant={"h2"} color={"primary"} paragraph>
									Extract Insights with ML
								</Typography>
								<Typography paragraph>
									Leverage cutting-edge Machine and Deep Learning techniques to aid in
									improving your workflows as well as solving your bioinformatics and cheminformatics
									problems in omics or biochemical data (e.g. gene calling and annotation or
									structural analyses).
								</Typography>
								<Typography paragraph>
									Internal tools like OmniA allow us to speed up the development problem by selecting
									optimized pipelines automatically.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}}}>
									<ProductCard
										useCase={{
											cover: `/assets/ai.jpg`,
											title: "",
											link: ""
										}}
										index={0}
									/>
								</Box>
							</Grid>
						</Grid>
					</div>
					<div>
						<Stack
							justifyContent="center"
							alignItems="center"
							spacing={3}
						>
							<>
								<Typography variant={"h2"} color={"primary"}>
									Your Data. Our Solutions.
								</Typography>
								<Typography>
									OmniumAI can help you build a custom solution for your business.
								</Typography>
							</>
							<Button color={'primary'}
							        variant="contained"
							        component={RouterLink}
							        size={"large"}
							        to={"mailto:info@omniumai.com"}>
								Talk to us
							</Button>
						</Stack>
					</div>
				</Stack>
			</Container>
		</>
	)
}
