import {useState} from "react";
import {Helmet} from 'react-helmet-async';
// @mui
import {
    Grid,
    Container,
    Typography,
    Stack,
    Box,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Link,
    TableContainer,
    Table,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    Divider,
    TableHead
} from '@mui/material';
import {
    CalendarTodayOutlined,
    FiberManualRecord,
    LocalOfferOutlined,
    LocationOnOutlined,
    SendOutlined,
    TranslateOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function OverviewTabPanel() {
    return (
        <Box>
            <Typography variant="h3" paragraph>
                Introduction
            </Typography>
            <Typography align={"justify"}>
                The AILS (Artificial Intelligence for the Life Sciences) course is an exciting opportunity to explore the cutting-edge applications of machine learning and deep learning in the field of life sciences.

                The AILS course offers an excellent opportunity for participants to gain practical skills in artificial intelligence techniques applied to the life sciences. With a focus on hands-on learning and accessible content, this course promises to equip participants with the necessary tools to tackle real-world challenges in their respective fields.

                The course will take place in Braga from September 4th to 9th, 2023. It is jointly organized by OmniumAI and NEBIUM - Núcleo de Estudos de Bioinformática da Universidade do Minho.
            </Typography>
            <Typography variant="h3" paragraph sx={{pt: 5}}>
                Target Audience
            </Typography>
            <Typography align={"justify"}>
                AILS is accessible to non-programmers who have some experience with Python scripts and Jupyter notebooks, while a short introduction to Python programming and relevant packages (e.g., pandas) will be provided prior to the course (online session). Participants are encouraged to bring their own data, allowing them to apply the learned techniques to real-world problems they face in their respective fields.
            </Typography>
            <Typography variant="h3" paragraph sx={{pt: 5}}>
                Learning Outcomes
            </Typography>
            <Typography align={"justify"}>
                The course aims to provide participants with state-of-the-art knowledge and hands-on experience in artificial intelligence applied to the life sciences. The course will address the following topics:
            </Typography>
            <List sx={{pl: 2}}>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Basic data manipulation and analysis in Python" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Machine learning core concepts and tools" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Basic bioinformatics concepts and tools" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Basic omics data processing and analysis" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Machine learning applied to biological sequences and omics data to predict phenotypes" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Basic cheminformatics concepts and tools" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Machine learning applied to chemical compounds to predict properties and activity" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Deep learning core concepts and tools" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Deep learning models for biological and biomedical applications" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Generative deep learning models to generate compounds" />
                </ListItem>
            </List>
            <Typography align={"justify"}>
                Throughout the course, participants will work with a variety of tools and libraries, including Python scripts and Jupyter notebooks. Participants will also be introduced to the several Python libraries, essential to analyze life sciences data and to develop machine learning and deep learning models.
            </Typography>
            <List sx={{pl: 2}}>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Pandas" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Scikit-learn & TensorFlow/Keras" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>
                        RDKit,
                        DeepChem (
                        <Link sx={{px: 0.5}} href={"https://deepchem.io/"} target={"_blank"} rel={"noopener"}>Docs</Link>
                        |
                        <Link sx={{px: 0.5}} href={"https://github.com/deepchem/deepchem"} target={"_blank"} rel={"noopener"}>GitHub</Link>
                        |
                        <Link sx={{px: 0.5}} href={"https://www.oreilly.com/library/view/deep-learning-for/9781492039822/"} target={"_blank"} rel={"noopener"}>Book</Link>
                        )
                        &
                        DeepMol (
                        <Link sx={{px: 0.5}} href={"https://deepmol.readthedocs.io/en/latest/"} target={"_blank"} rel={"noopener"}>Docs</Link>
                        |
                        <Link sx={{px: 0.5}} href={"https://github.com/BioSystemsUM/DeepMol"} target={"_blank"} rel={"noopener"}>GitHub</Link>
                        |
                        <Link sx={{px: 0.5}} href={"https://www.doi.org/10.1109/IJCNN55064.2022.9891992"} target={"_blank"} rel={"noopener"}>Citation</Link>
                        )
                    </ListItemText>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>
                        Biopython &
                        ProPythia
                        (<Link sx={{px: 0.5}} href={"https://github.com/BioSystemsUM/propythia"} target={"_blank"} rel={"noopener"}>GitHub</Link>
                        | <Link sx={{px: 0.5}} href={"https://dx.doi.org/10.1016/j.neucom.2021.07.102"} target={"_blank"} rel={"noopener"}>Citation</Link>
                        )
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h3" paragraph sx={{pt: 5}}>
                Practical Applications
            </Typography>
            <Typography align={"justify"}>
                The practical applications of the course are vast, including phenotype prediction, biomarker discovery, protein classification, prediction of compound activities and properties, and the generation of novel compounds.
            </Typography>
        </Box>
    )
}

function RegistrationTabPanel() {
    return (
        <Box>
            <Typography variant={"h3"} paragraph sx={{pt: 5}}>
                Deadlines
            </Typography>
            <List sx={{pl: 2}}>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>
                        Early registration: <s>until June 16th, 2023</s> until June 24th, 2023
                    </ListItemText>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Regular registration: until July 16th, 2023" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <FiberManualRecord fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText primary="Late registration: until August 31st, 2023" />
                </ListItem>
            </List>
            <Typography variant={"h3"} paragraph sx={{pt: 5}}>
                Payment
            </Typography>
            <Typography align={"justify"}>
                Please note that your registration is only valid after the payment of the registration fee. We only accept bank transfer payments.
            </Typography>
            <Typography align={"justify"}>
                The details for the payment by bank transfer will be sent to you by e-mail. You will have 15 days to finish the payment process.
            </Typography>
        </Box>
    )
}

function ProgrammeTabPanel() {

    function createData(time,
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday) {
        return {
            time,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
        }
    }

    const sessions = [
        "Session 0 – Introduction to data manipulation in Python",
        "Session 1 – Introduction to unsupervised machine learning",
        "Session 2 – Introduction to supervised machine learning",
        "Session 3 – Machine learning for omics data",
        "Session 4 – Machine learning for sequences",
        "Session 5 – Cheminformatics tools",
        "Session 6 – Machine learning models for cheminformatics",
        "Session 7 – Deep learning introduction",
        "Session 8 – Deep learning: Recurrent neural networks",
        "Session 9 – Deep learning: Other architectures",
        "Session 10 – Generative deep learning models for compounds"
    ]

    const rows = [
        createData(
            '09:30 - 12:45',
            "S0 - Registration & Python introduction Q&A",
            "S2 - Supervised ML",
            "S4 - ML for sequences",
            "S6 - ML for cheminformatics",
            "S8 - DL: Recurrent neural networks",
            "S10 - Generative DL for compounds"
        ),
        createData(
            '14:30 - 17:45',
            "S1 - Unsupervised ML",
            "S3 - ML for omics data",
            "S5 - Cheminformatics tools",
            "S7 - DL: Introduction",
            "S9 - DL: Other architectures",
            "Free - "
        )
    ]

    return (
        <Box>
            <Typography variant={"h3"} paragraph>
                Programme
            </Typography>
            <Typography align={"justify"}>
                Your journey begins with the basics of machine learning in Python and gradually progresses towards
                advanced concepts like generative deep learning models for compounds. Check out the programme below:
            </Typography>

            <TableContainer component={Paper} sx={{mb: 3}}>
                <Table sx={{ minWidth: 900 }} aria-label="program table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell align="left">Monday - Sep 04th</TableCell>
                            <TableCell align="left">Tuesday - Sep 05th</TableCell>
                            <TableCell align="left">Wednesday - Sep 06th</TableCell>
                            <TableCell align="left">Thursday - Sep 07th</TableCell>
                            <TableCell align="left">Friday - Sep 08th</TableCell>
                            <TableCell align="left">Saturday - Sep 09th</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.time}
                                </TableCell>
                                <TableCell align="left">
                                    {row.monday.split(' - ')[0]}
                                    <br />
                                    {row.monday.split(' - ')[1]}
                                </TableCell>
                                <TableCell align="left">
                                    {row.tuesday.split(' - ')[0]}
                                    <br />
                                    {row.tuesday.split(' - ')[1]}
                                </TableCell>
                                <TableCell align="left">
                                    {row.wednesday.split(' - ')[0]}
                                    <br />
                                    {row.wednesday.split(' - ')[1]}
                                </TableCell>
                                <TableCell align="left">
                                    {row.thursday.split(' - ')[0]}
                                    <br />
                                    {row.thursday.split(' - ')[1]}
                                </TableCell>
                                <TableCell align="left">
                                    {row.friday.split(' - ')[0]}
                                    <br />
                                    {row.friday.split(' - ')[1]}
                                </TableCell>
                                <TableCell align="left">
                                    {row.saturday.split(' - ')[0]}
                                    <br />
                                    {row.saturday.split(' - ')[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant={"h3"} paragraph>
                Sessions
            </Typography>
            <Typography align={"justify"}>
                In this course, you will learn essential topics of AI such as data manipulation,
                machine learning and deep learning.
                The course will focus primarily in AI applied to cheminformatics and bioinformatics
                for analyzing biological and biochemical data. Check out the sessions below:
            </Typography>

            <List sx={{pl: 2}}>
                {sessions.map((session, index) => (
                    <>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecord fontSize={"small"} />
                            </ListItemIcon>
                            <ListItemText primary={session} key={index} />
                        </ListItem>
                    </>
                ))}
            </List>
        </Box>
    )
}

function CoordinationTabPanel() {

    return (
        <Box>
            <Typography variant={"h3"} paragraph>
                Scientific Coordination
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <img src={"/assets/mrocha.jpg"} alt={"Miguel Rocha"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        Miguel Rocha <br/>
                        Associate Professor with Habilitation @DI-UMinho & @CEB-UMinho
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        Miguel Rocha is an Associate Professor in Artificial Intelligence and Bioinformatics,
                        being the founder of the MSc in Bioinformatics (2007) and its current Director.
                        He is currently the CSO of OmniumAI. He has 20 years of experience in applying AI and
                        data science technologies to biological and biomedical data, both in academic
                        (with numerous publications) and in industry scenarios.
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant={"h3"} paragraph>
                Team
            </Typography>
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <img src={"https://www.ceb.uminho.pt/Media/GetMedia/abd55d9c-0cf7-4df5-a5ff-e011e90fddc7"}
                         alt={"JC"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        João Capela <br/>
                        PhD student @CEB-UMinho
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        João Capela is a PhD student in Informatics. He is currently working with machine learning to
                        accelerate the discovery of novel chemical compounds. He is one of the main developers of DeepMol.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={"/assets/jcorreia.png"}
                         alt={"JC2"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        João Correia <br/>
                        PhD student @CEB-UMinho
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        João Correia is a PhD student in Informatics currently working with machine learning methods applied to the discovery of new chemical compounds and reactions. He is one of the main developers of DeepMol.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={"/assets/msampaio.jpg"}
                         alt={"JC"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        Marta Sampaio <br/>
                        PhD student @CEB-UMinho
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        Marta Sampaio is a PhD student in Informatics. She is currently working in machine learning to study the metabolism of plants. She was a Teaching Assistant at UMinho in the areas of Bioinformatics. She is the main developer of PhagePromoter.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={"https://www.ceb.uminho.pt/Media/GetMedia/a7d9547d-6110-40f3-b73b-097311619c21"}
                         alt={"JC"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        Ana Marta Sequeira <br/>
                        PhD student @CEB-UMinho
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        Ana Marta Sequeira is a PhD student in Informatics. She has a vast experience in machine learning applied to protein sequences. She is the main developer of ProPythia.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={"/assets/fcruz.jpg"}
                         alt={"JC"} style={{height: "200px"}}/>
                    <Typography variant={"h5"} paragraph>
                        Fernando Cruz <br/>
                        CTO @OmniumAI
                    </Typography>
                    <Typography align={"justify"} paragraph>
                        Fernando Cruz is a former PhD student in Biomedical Engineering. He is currently working as CTO of OmniumAI. He has a vast experience in data engineering. He was a Teaching Assistant at
                        UMinho in the areas of Bioinformatics and Artificial Intelligence. Machine learning algorithms package SI.
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    )
}

function TravelTabPanel() {

    return (
        <Box>
            <Typography variant={"h3"} paragraph>
                Travel
            </Typography>
            <Typography align={"justify"} paragraph>
                The course will be held at the city of Braga.
            </Typography>
            <Typography variant={'h5'} paragraph>
                By Plane
            </Typography>
            <Typography align={"justify"} paragraph>
                The Airport of Porto (Sá Carneiro Airport) is the nearest airport of the venue. This airport is located in Porto (OPO), about 50 Km away from the venue. From the airport, one can take the metro line E (violet) direction Aeroporto to Estádio do Dragão. You should take the Campanhã train station exit. There are several trains to Braga that depart from this station. Alternatively, getBUS provides a direct bus service between the airport and Braga's train station.

                Check the following services for more information:
            </Typography>
            <Typography align={"justify"} paragraph>
                <Link sx={{pr: 0.5}} href={"https://www.ana.pt/"} target={"_blank"} rel={"noopener"}>Sá Carneiro Airport</Link>
                - Phone: + 351 229 432 400 <br/>

                <Link sx={{pr: 0.5}} href={"https://www.metrodoporto.pt/"} target={"_blank"} rel={"noopener"}>Porto Metro</Link>
                - Phone: +351 225 081 000 <br/>

                <Link sx={{pr: 0.5}} href={"https://www.getbus.eu/"} target={"_blank"} rel={"noopener"}>getBUS</Link>
                - Phone: +351 253 262 371
            </Typography>
            <Typography variant={'h5'} paragraph>
                By Bus
            </Typography>
            <Typography align={"justify"} paragraph>
                Check the following services for more information:
            </Typography>
            <Typography align={"justify"} paragraph>
                <Link sx={{pr: 0.5}} href={"https://www.transdev.pt/"} target={"_blank"} rel={"noopener"}>Transdev</Link>
                - Phone: +351 213 581 460 <br/>

                <Link sx={{pr: 0.5}} href={"https://www.arriva.pt/"} target={"_blank"} rel={"noopener"}>Arriva</Link>
                - Phone: +351 253 264 693 <br/>

                <Link sx={{pr: 0.5}} href={"https://www.rodonorte.pt/"} target={"_blank"} rel={"noopener"}>Rodonorte</Link>
                - Phone: +351 259 340 710
            </Typography>
            <Typography variant={'h5'} paragraph>
                By Train
            </Typography>
            <Typography align={"justify"} paragraph>
                Information on the available rail lines to get to Braga and train schedules may be found at Comboios de Portugal (CP). The train station in Braga is a 15 minutes bus trip away from the Gualtar campus. Bus service is provided by Transportes Urbanos de Braga (TUB).

                Check the following services for more information:
            </Typography>
            <Typography align={"justify"} paragraph>
                <Link sx={{pr: 0.5}} href={"https://www.cp.pt/"} target={"_blank"} rel={"noopener"}>CP</Link>
                - Phone: +351 808 109 110 <br/>

                <Link sx={{pr: 0.5}} href={"https://www.tub.pt/"} target={"_blank"} rel={"noopener"}>TUB</Link>
                - Phone: +351 253 606 890
            </Typography>
            <Typography variant={"h3"} paragraph sx={{pt: 5}}>
                Accommodation
            </Typography>
            <Typography align={"justify"} paragraph>
                <b>Hotel Meliá *****</b> - Located just minutes from downtown Braga and the University of Minho. <br/>

                <Link sx={{pr: 0.5}} href={"https://www.melia.com/pt"} target={"_blank"} rel={"noopener"}>Meliá Portugal</Link>
                - Phone: +351 253 144 000
            </Typography>
            <Typography align={"justify"} paragraph>
                <b>B&B Hotel Braga Lamaçães ***</b> - Located near the University of Minho, the Iberian Nanotechnology Centre, and a 5 minutes drive from Braga's historic center. <br/>

                <Link sx={{pr: 0.5}} href={"https://www.hotel-bb.com/en/hotel/braga-lamacaes"} target={"_blank"} rel={"noopener"}>B&B Hotel Braga Lamaçães</Link>
                - Phone: +351 253 603 680
            </Typography>
        </Box>
    )
}

function TabsBox({value, width, handleChange, a11yProps}) {

    return (
        <Box sx={{maxWidth: width}}>
            <Tabs value={value}
                  variant="scrollable"
                  scrollButtons={true}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Registration" {...a11yProps(1)} />
                <Tab label="Programme" {...a11yProps(2)} />
                <Tab label="Coordination & Team" {...a11yProps(3)} />
                <Tab label="Travel & Accommodation" {...a11yProps(4)} />
            </Tabs>
        </Box>
    )
}

// ----------------------------------------------------------------------
export default function AILSPage() {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet>
                <title> OmniumAI </title>
            </Helmet>
            <Grid container
                  sx={{minHeight: '60vh',
                      minWidth: '100vw',
                      pl: {xs: 1, sm: 2, md: 5},
                      backgroundColor: "rgba(192,201,200,0.3)"}}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" sx={{pt: 5}}>
                        AILS - CLOSED
                    </Typography>
                    <Typography variant="h3" sx={{pt: 5}} paragraph>
                        Artificial Intelligence for the Life Sciences
                    </Typography>
                    <Typography
                        sx={{my: 5}}
                        variant="h4"
                        paragraph>
                        All you need to know to get started with AI in the life sciences.
                    </Typography>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={0} md={6}>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} sx={{pb: 2}}>
                        <img src={'/assets/course_ai4ls.jpg'} alt={'ails'} height={'300px'}/>
                    </Stack>
                </Grid>
            </Grid>
            <Container maxWidth="xl"
                       sx={{px: {xs: 1, md: 3}, pt: {xs: 5}, pb: 3}}>
                <Grid container
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                      spacing={2}>
                    <Grid item order={{xs: 2, md: 1}} xs={12} md={10}>
                        {matchesLG ?
                            <TabsBox value={value}
                                     width={"800px"}
                                     handleChange={handleChange}
                                     a11yProps={a11yProps}/>
                            : matchesMD ?
                                <TabsBox value={value}
                                         width={"550px"}
                                         handleChange={handleChange}
                                         a11yProps={a11yProps}/>
                                : matchesSM ?
                                    <TabsBox value={value}
                                             width={"400px"}
                                             handleChange={handleChange}
                                             a11yProps={a11yProps}/>
                                    :
                                    <TabsBox value={value}
                                             width={"350px"}
                                             handleChange={handleChange}
                                             a11yProps={a11yProps}/>
                        }
                        <TabPanel value={value} index={0}>
                            <OverviewTabPanel/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <RegistrationTabPanel/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <ProgrammeTabPanel/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <CoordinationTabPanel/>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <TravelTabPanel/>
                        </TabPanel>
                    </Grid>
                    <Grid item order={{xs: 1, md: 2}} xs={12} md={2}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CalendarTodayOutlined />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={"h6"}>
                                        September 04th - 09th 2023
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={"h6"}>
                                        Braga, Portugal (<b>onsite</b>)
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemIcon>
                                    <LocalOfferOutlined />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={"h6"}>
                                        From 450€
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemIcon>
                                    <TranslateOutlined />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={"h6"}>
                                        English
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemIcon>
                                    <SendOutlined />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={"h6"}>
                                        <Link href={"mailto:academy@omniumai.com"}>
                                            Contact us
                                        </Link>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}