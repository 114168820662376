import {Helmet} from 'react-helmet-async';
// @mui
import {Container, Typography, Stack, List, ListItemText} from '@mui/material';

// ----------------------------------------------------------------------
export default function GrantsPage() {
    const topics = [
        "Voucher para Startups",
    ]

    const voucher_startup_topics = [
        {topic: "Designação do projeto", content: "Green Computing - Otimização de processos AutoML"},
        {topic: "N.º do projeto", content: "1370"},
        {
            topic: "Objetivo principal",
            content: "Promoção de modelos de negócio, produtos ou serviços digitais com contributo positivo para a transição climática através de elevada eficiência na utilização de recursos, que permitam a redução dos impactos da poluição e que se caracterizem pela utilização de Dados Abertos ou de Inteligência Artificial"
        },
        {topic: "Região de intervenção", content: "Norte"},
        {
            topic: "Objetivo principal",
            content: "Promoção de modelos de negócio, produtos ou serviços digitais com contributo positivo para a transição climática através de elevada eficiência na utilização de recursos, que permitam a redução dos impactos da poluição e que se caracterizem pela utilização de Dados Abertos ou de Inteligência Artificial"
        },
        {topic: "Entidade Beneficiária", content: "OMNIUMAI - INTELIGÊNCIA ARTIFICIAL E CIÊNCIAS DE DADOS LDA"},
        {topic: "Data de Aprovação", content: "26/10/2023"},
        {topic: " Data de início", content: " 01/01/2023"},
        {topic: "Data de conclusão", content: "25/05/2025"},
        {topic: "Custo total elegível", content: " 30.000,00 euros"},
        {topic: "Apoio financeiro da União Europeia", content: "30.000,00 euros"},
        {
            topic: "Objetivos, atividades e resultados esperados/atingidos",
            content: "Integração de métodos e conceitos da área do green computing de forma a quantificar os impactos ambientais dos processos de AutoML e integração de processos de sustentabilidade na plataforma de Machine Learning automatizados."
        },
    ]

    return (
        <>
            <Helmet>
                <title> OmniumAI </title>
            </Helmet>
            <Container maxWidth="xl"
                       sx={{px: {xs: 1, md: 3}, py: 5}}>
                <Stack spacing={{xs: 3, md: 5, lg: 8}}>
                    <div>
                        <Typography variant={"h2"} paragraph>
                            OmniumAI's Grant Funding
                        </Typography>
                        <Typography paragraph>
                            This page discloses the grant and public funding awarded to OmniumAI.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant={"h3"} paragraph>
                            Grants
                        </Typography>
                        <List>
                            {topics.map((topic, index) => (
                                <ListItemText key={index} primary={topic}/>
                            ))}
                        </List>
                    </div>
                    <div>
                        <Typography variant={"h2"} paragraph>
                            Voucher para Startups
                        </Typography>
                        {
                            voucher_startup_topics.map((paragraph) =>
                                <Typography paragraph component="div">
                                    <Typography color={"primary"}
                                                component={'span'}
                                                variant={"h4"}>{paragraph.topic}
                                    </Typography>: {paragraph.content}
                                </Typography>
                            )
                        }
                        <Typography paragraph>
                            Projeto apoiado no âmbito do Plano de Recuperação e Resiliência (recuperarportugal.gov.pt)
                        </Typography>
                    </div>
                </Stack>
            </Container>
        </>
    )
}