import {IconButton, Link, Stack, Typography} from "@mui/material";
import {Copyright, LinkedIn} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const StyledBanner = styled('img')({
    top: 0,
    // width: '100px',
    height: '50px',
    // objectFit: 'cover',
    // position: 'absolute',
});


export default function Footer() {

    return (
        <Stack sx={{width: '100vw', pt: 5, px: 5}}
               direction={'column'}
               justifyContent={'center'}
               alignItems={'center'}
               spacing={4}>
            <Stack direction={"row"}
                   justifyContent={'center'}
                   alignItems={'center'}
                   spacing={1}>
                <Typography variant={'body2'} color={'text.secondary'}>
                    All Rights Reserved
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    <Copyright sx={{fontSize: 10}}/> OmniumAI 2023
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <Link variant={'body2'}
                      color={'text.secondary'}
                      href={"/privacy"}>
                    Privacy Policy
                </Link>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <Link variant={'body2'}
                      color={'text.secondary'}
                      href={"/grants"}>
                    Grant Funding
                </Link>
                <Typography variant={'body2'} color={'text.secondary'}>
                    |
                </Typography>
                <IconButton href={'https://www.linkedin.com/company/omniumai'}>
                    <LinkedIn/>
                </IconButton>
            </Stack>
            <Stack direction={"row"}
                   justifyContent={'center'}
                   alignItems={'center'}
                   spacing={1}>
                <StyledBanner alt={"prr"} src={"/assets/_BARRA_LOGOS-COR.png"}/>
                <StyledBanner alt={"iefp"} src={"/assets/barra_pessoas_2030.png"}/>
            </Stack>
        </Stack>
    );
}