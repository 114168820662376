import {Avatar, Box, Card, CardContent, Grid, IconButton, Typography} from '@mui/material';

export default function TeamCard({member}) {

    const color = member.title === 'CEO' ? 'success.main' :
        member.title === 'CSO' ? 'error.main' :
            member.title === 'CTO' ? 'warning.dark' :
                member.title === 'CBDO' ? 'warning.main' :
                    'primary';

    return (
        <Grid item
              xs={12}
              md={6}
              lg={4}
        >

            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px'
                }}
            >
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            pb: 3
                        }}
                    >
                        <IconButton href={member.linkedin} target={'_blank'} rel={'noopener noreferrer'}>
                            <Avatar
                                sx={{ width: 56, height: 56 }}
                                src={member.image}
                            />
                        </IconButton>
                    </Box>
                    <Typography
                        gutterBottom
                        variant="h5"
                    >
                        {member.name} | <Typography gutterBotton variant={'h5'} component={'span'} color={color}>{member.title}</Typography>
                    </Typography>
                    <Typography
                        variant="body1"
                    >
                        {member.description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};