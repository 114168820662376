import {Helmet} from 'react-helmet-async';
// @mui
import {Grid, Container, Typography, Stack, Button, Box} from '@mui/material';
// routes
import {Link as RouterLink} from "react-router-dom";
// components
import ProductCard from "../components/product-card";


// ----------------------------------------------------------------------
export default function ProductsPage() {
	const backgroundImageStyle = {
		backgroundColor: "#212B36",
		backgroundImage: `url('/assets/products.jpg')`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPositionX: 'right',
		backgroundPositionY: 'bottom',
	}

	return (
		<>
			<Helmet>
				<title> OmniumAI </title>
			</Helmet>
			<Grid container
			      sx={{
				      minHeight: '70vh',
				      minWidth: '100vw',
				      pl: {xs: 1, sm: 2, md: 5},
				      ...backgroundImageStyle
			      }}
			      justifyContent={"center"}
			      alignItems={"center"}
			      spacing={3}>
				<Grid item xs={12} md={4}>
					<Typography variant="h2" sx={{pt: 5}} color={"background.default"}>
						Our Products
					</Typography>
					<Typography
						sx={{my: 5}}
						variant="h4"
						color={"background.default"}
						paragraph>
						Ranging from
						<Typography color={"error.main"} component={'span'}
						            variant={"h4"}>{' '} AI-powered {' '}</Typography>
						food product re-design to food traceability using NGS.
					</Typography>
					<Box sx={{pb: {xs: 2, sm: 0}}}>
						<Button
							variant="contained"
							component={RouterLink}
							size={"large"}
							color={"primary"}
							to={"mailto:info@omniumai.com"}>
							Get in touch
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12} md={8}>
				</Grid>
			</Grid>
			<Container maxWidth="xl"
			           sx={{px: {xs: 1, md: 3}, pt: {xs: 5, md: 10}, pb: 3}}>
				<Stack spacing={{xs: 10, md: 15, lg: 20}}>
					<div>
						<Grid container alignItems="center" justifyContent="center" spacing={3}>
							<Grid item xs={12} sm={8}>
								<Typography variant={"h2"} align={"center"} paragraph>
									Engineered for Immediate and Lasting Impact
								</Typography>
								<Typography variant={'overline'} color={"success.main"} align={"center"} fontSize={16}
								            paragraph>
									Digital innovation is at the heart of modern biotech companies.
								</Typography>
								<Typography align={"center"} paragraph>
									In an age where increasing amounts of data are generated in the Life Sciences. <br/>
									We have the appropriate tools to gather,
									store and process your data efficiently and securely.
								</Typography>
							</Grid>
						</Grid>
						<Grid container
						      alignItems="center"
						      justifyContent="center"
						      columnSpacing={3}
						      rowSpacing={{xs: 3, md: 20}}
						      sx={{pt: 20}}>
							<Grid item order={{xs: 1, md: 1}} xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}}}>
									<ProductCard
										useCase={{
											cover: `/assets/yogurt.jpg`,
											title: "",
											link: ""
										}}
										index={0}/>
								</Box>
							</Grid>
							<Grid item order={{xs: 2, md: 2}} xs={12} md={6}>
								<Stack direction={'row'}
								       justifyContent={'flex-start'}
								       alignItems={"center"}
								       sx={{pb: {xs: 5, md: 5}}}>
									<img src={'/assets/cibus_B.png'} alt={'cibus'} height={'100px'}/>
								</Stack>
								<Typography variant={'overline'} color={'primary.main'} fontSize={20} paragraph>
									Finding alternative ingredients is slow!
								</Typography>
								<Typography paragraph>
									CibusAI empowers food companies with AI-driven reformulation,
									reimagining their products for better taste and improved health.
								</Typography>
								<Typography paragraph>
									CibusAI can be used to quickly find alternative ingredients
									having similar functional and organoleptic properties.
									Short lead times allow you to quickly respond to market changes.
								</Typography>
								<Typography paragraph>
									CibusAI is a cloud-based no-code platform easy to use for food scientists and
									technologists.
								</Typography>
								<Box sx={{pb: {xs: 2, sm: 0}}}>
									<Button
										variant="contained"
										component={RouterLink}
										size={"large"}
										color={"primary"}
										to={"https://cibus.omniumai.com"}>
										Know More
									</Button>
								</Box>
							</Grid>
							<Grid item order={{xs: 4, md: 3}} xs={12} md={6}>
								<Stack direction={'row'}
								       justifyContent={'flex-start'}
								       alignItems={"center"}
								       sx={{pb: {xs: 5, md: 5}}}>
									<img src={'/assets/omnia_green.png'} alt={'omnia'} height={'100px'}/>
								</Stack>
								<Typography variant={'overline'} color={'success.main'} fontSize={20} paragraph>
									AI Unleashed at Your Command!
								</Typography>
								<Typography paragraph>
									OmniA is an artificial intelligence platform for the biotech industry.
									This platform uses automated techniques to create accurate machine-learning models
									with minimal effort.
								</Typography>
								<Typography paragraph>
									OmniA's models can be created from different data types and serve varied
									applications
									ranging from the sensitivity classification of new drugs to identifying new chemical
									compounds for the food industry.
								</Typography>
								<Typography paragraph>
									Offset the risk of integrating AI into your research workflow, by using our no-code
									cloud-based platform.
								</Typography>
							</Grid>
							<Grid item order={{xs: 3, md: 4}} xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}}}>
									<ProductCard
										useCase={{
											cover: `/assets/ai_img.png`,
											title: "",
											link: ""
										}}
										index={0}
									/>
								</Box>
							</Grid>
							<Grid item order={{xs: 5, md: 5}} xs={12} md={12}>
								<Typography variant={'body1'} color={'success.main'} fontSize={36}
								            align={"center"} paragraph>
									Did you know?
								</Typography>
								<Typography align={"center"} paragraph>
									OmniA is supercharging CibusAI! <br/>
									OmniA is being used as cloud-based no-code platform to develop machine and deep
									learning models for CibusAI. <br/>
									At OmniumAI, we believe that OmniA will be a key component to accelerate the
									development of CibusAI.
								</Typography>
							</Grid>
							<Grid item order={{xs: 6, md: 6}} xs={12} md={6}>
								<Box sx={{pl: {xs: 0, md: 15}, pr: {xs: 3, md: 15}, mt: {xs: 5, md: 0}}}>
									<ProductCard
										useCase={{
											cover: `/assets/omics_img.png`,
											title: "",
											link: ""
										}}
										index={0}/>
								</Box>
							</Grid>
							<Grid item order={{xs: 7, md: 7}} xs={12} md={6}>
								<Stack direction={'row'}
								       justifyContent={'flex-start'}
								       alignItems={"center"}
								       sx={{pb: {xs: 5, md: 5}}}>
									<img src={'/assets/omniNGS.png'} alt={'cibus'} height={'100px'}/>
								</Stack>
								<Typography variant={'overline'} color={'warning.main'} fontSize={20} paragraph>
									Unveil the Truth in Your food products!
								</Typography>
								<Typography paragraph>
									OmniNGS is a computational framework for Food Traceability that employs
									Next Generation Sequencing to safeguard your food products.
									OmniNGS meticulously analyses the DNA signatures within food samples,
									ensuring authenticity and eliminating food fraud.
								</Typography>
								<Typography paragraph>
									It performs a pipeline of bioinformatics and sequence assembly tools to identify
									the species present in a food sample.
								</Typography>
								<Typography paragraph>
									Don't compromise your product's integrity. Harness the power of our
									cloud-based platform to confidently validate the composition of your food products.
								</Typography>
							</Grid>
						</Grid>
					</div>
					<div>
						<Stack
							justifyContent="center"
							alignItems="center"
							spacing={3}>
							<>
								<Typography variant={"h2"} color={"primary"}>
									Can't find what you're looking for?
								</Typography>
								<Typography>
									OmniumAI can help you build a custom solution for your business.
								</Typography>
							</>
							<Button color={'primary'}
							        variant="contained"
							        component={RouterLink}
							        size={"large"}
							        to={"mailto:info@omniumai.com"}>
								Talk to us
							</Button>
						</Stack>
					</div>
				</Stack>
			</Container>
		</>
	)
}