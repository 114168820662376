import {useState} from "react";
import PropTypes from 'prop-types';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {
    Box,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    Link, Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// ----------------------------------------------------------------------

const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;


function bgBlur(props) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    };
}

const StyledRoot = styled(AppBar)(({theme}) => ({
    ...bgBlur({color: theme.palette.background.default}),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

const pages = [
        {
        name: "Home",
        link: "/home"
    },
    {
        name: "Products",
        link: "/products"

    },
    {
        name: "Services",
        link: "/services"
    },
    {
        name: "Academy",
        link: "/academy"

    }
]

export default function Header() {
    const [state, setState] = useState(false);

    function openNav() {
        setState(true)
    }

    function closeNav() {
        setState(false)
    }


    return (
        <StyledRoot>
            <StyledToolbar>
                <Box sx={{flexGrow: 1}}>
                    <Link
                        sx={{cursor: 'pointer'}}
                        underline="none"
                        href={"/home"}>
                        <img src="/assets/logo_extended.svg" alt="OmniumAI" height={50}/>
                    </Link>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="info"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={openNav}>
                        <MenuIcon/>
                    </IconButton>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                    {pages.map((page) => (
                        <Button
                            key={page}
                            sx={{my: 2, color: 'info', display: 'block'}}
                            href={page.link}
                        >
                            <Typography variant={"h6"}>
                                {page.name}
                            </Typography>
                        </Button>
                    ))}
                </Box>

                <Drawer
                    PaperProps={{sx: {width: "40%"}}}
                    anchor="right"
                    open={state}
                    onClose={closeNav}>
                    <List sx={{pt: 5}}>
                        {pages.map((page) => (
                            <ListItem key={page.name}>
                                <Button
                                    onClick={closeNav}
                                    sx={{color: 'info', display: 'block'}}
                                    href={page.link}>
                                    {page.name}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </StyledToolbar>
        </StyledRoot>
    );
}